"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_FINANCIAL_STATUS_SETTING = exports.UPSERT_SUCCESS_STORY = exports.DELETE_SUCCESS_STORY = exports.DELETE_DROPDOWN_OPTIONS = exports.UPSERT_DROPDOWN_OPTIONS = exports.DELETE_FINANCIAL_INFO = exports.UPSERT_FINANCIAL_TABULAR_INDIVIDUAL_OPTIONS = exports.UPSERT_FINANCIAL_INF0_INDIVIDUAL = exports.UPSERT_FINANCIAL_INFO = exports.UPSERT_FINANCIAL_CATEGORY = void 0;
var client_1 = require("@apollo/client");
var UPSERT_FINANCIAL_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation upsertFinancialCategory(\n\t\t$id: ID\n\t\t$name: String!\n\t\t$parent_id: ID\n\t\t$user_type: [ID!]\n\t\t$organization_id: ID!\n\t\t$order_by: Int\n\t\t$frontend_component: String\n\t\t$link_id: ID\n\t) {\n\t\tupsertFinancialCategory(\n\t\t\tid: $id\n\t\t\tname: $name\n\t\t\tparent_id: $parent_id\n\t\t\tuser_type: $user_type\n\t\t\torganization_id: $organization_id\n\t\t\tfrontend_component: $frontend_component\n\t\t\torder_by: $order_by\n\t\t\tlink_id: $link_id\n\t\t)\n\t}\n"], ["\n\tmutation upsertFinancialCategory(\n\t\t$id: ID\n\t\t$name: String!\n\t\t$parent_id: ID\n\t\t$user_type: [ID!]\n\t\t$organization_id: ID!\n\t\t$order_by: Int\n\t\t$frontend_component: String\n\t\t$link_id: ID\n\t) {\n\t\tupsertFinancialCategory(\n\t\t\tid: $id\n\t\t\tname: $name\n\t\t\tparent_id: $parent_id\n\t\t\tuser_type: $user_type\n\t\t\torganization_id: $organization_id\n\t\t\tfrontend_component: $frontend_component\n\t\t\torder_by: $order_by\n\t\t\tlink_id: $link_id\n\t\t)\n\t}\n"])));
exports.UPSERT_FINANCIAL_CATEGORY = UPSERT_FINANCIAL_CATEGORY;
var UPSERT_FINANCIAL_INFO = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tmutation upsertFinancialInfo($input: [InfoPayload!]!) {\n\t\tupsertFinancialInfo(input: $input)\n\t}\n"], ["\n\tmutation upsertFinancialInfo($input: [InfoPayload!]!) {\n\t\tupsertFinancialInfo(input: $input)\n\t}\n"])));
exports.UPSERT_FINANCIAL_INFO = UPSERT_FINANCIAL_INFO;
var UPSERT_FINANCIAL_INF0_INDIVIDUAL = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tmutation upsertFinancialInfoIndividual($input: InfoPayload) {\n\t\tupsertFinancialInfoIndividual(input: $input) {\n\t\t\tid\n\t\t\tdata_type_id\n\t\t\tfinancial_category_id\n\t\t\tname\n\t\t\tuser_types {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\torder_by\n\t\t\tdata_type {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\tdependent_id\n\t\t\torganization_id\n\t\t\tcode\n\t\t\teditable\n\t\t\tcalculation\n\t\t\tchild_count\n\t\t\tparent {\n\t\t\t\tchild_count\n\t\t\t\tid\n\t\t\t\tdata_type_id\n\t\t\t\tfinancial_category_id\n\t\t\t\tname\n\t\t\t\tuser_types {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\torder_by\n\t\t\t\tdata_type {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation upsertFinancialInfoIndividual($input: InfoPayload) {\n\t\tupsertFinancialInfoIndividual(input: $input) {\n\t\t\tid\n\t\t\tdata_type_id\n\t\t\tfinancial_category_id\n\t\t\tname\n\t\t\tuser_types {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\torder_by\n\t\t\tdata_type {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\tdependent_id\n\t\t\torganization_id\n\t\t\tcode\n\t\t\teditable\n\t\t\tcalculation\n\t\t\tchild_count\n\t\t\tparent {\n\t\t\t\tchild_count\n\t\t\t\tid\n\t\t\t\tdata_type_id\n\t\t\t\tfinancial_category_id\n\t\t\t\tname\n\t\t\t\tuser_types {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\torder_by\n\t\t\t\tdata_type {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"])));
exports.UPSERT_FINANCIAL_INF0_INDIVIDUAL = UPSERT_FINANCIAL_INF0_INDIVIDUAL;
var UPSERT_FINANCIAL_TABULAR_INDIVIDUAL_OPTIONS = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tmutation upsertTabularOption($input: OptionPayload) {\n\t\tupsertTabularOption(input: $input) {\n\t\t\tid\n\t\t}\n\t}\n"], ["\n\tmutation upsertTabularOption($input: OptionPayload) {\n\t\tupsertTabularOption(input: $input) {\n\t\t\tid\n\t\t}\n\t}\n"])));
exports.UPSERT_FINANCIAL_TABULAR_INDIVIDUAL_OPTIONS = UPSERT_FINANCIAL_TABULAR_INDIVIDUAL_OPTIONS;
var DELETE_FINANCIAL_INFO = (0, client_1.gql)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tmutation deleteFinancialInfo(\n\t\t$id: ID!\n\t\t$organization_id: ID!\n\t\t$financial_category_id: ID!\n\t) {\n\t\tdeleteFinancialInfo(\n\t\t\tid: $id\n\t\t\torganization_id: $organization_id\n\t\t\tfinancial_category_id: $financial_category_id\n\t\t)\n\t}\n"], ["\n\tmutation deleteFinancialInfo(\n\t\t$id: ID!\n\t\t$organization_id: ID!\n\t\t$financial_category_id: ID!\n\t) {\n\t\tdeleteFinancialInfo(\n\t\t\tid: $id\n\t\t\torganization_id: $organization_id\n\t\t\tfinancial_category_id: $financial_category_id\n\t\t)\n\t}\n"])));
exports.DELETE_FINANCIAL_INFO = DELETE_FINANCIAL_INFO;
var UPSERT_DROPDOWN_OPTIONS = (0, client_1.gql)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\tmutation upsertDropdownOptions($input: [InfoOptionPayload!]!) {\n\t\tupsertDropdownOptions(input: $input)\n\t}\n"], ["\n\tmutation upsertDropdownOptions($input: [InfoOptionPayload!]!) {\n\t\tupsertDropdownOptions(input: $input)\n\t}\n"])));
exports.UPSERT_DROPDOWN_OPTIONS = UPSERT_DROPDOWN_OPTIONS;
var DELETE_DROPDOWN_OPTIONS = (0, client_1.gql)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tmutation deleteDropDownOptions($id: ID!) {\n\t\tdeleteDropDownOptions(id: $id) {\n\t\t\tid\n\t\t}\n\t}\n"], ["\n\tmutation deleteDropDownOptions($id: ID!) {\n\t\tdeleteDropDownOptions(id: $id) {\n\t\t\tid\n\t\t}\n\t}\n"])));
exports.DELETE_DROPDOWN_OPTIONS = DELETE_DROPDOWN_OPTIONS;
var DELETE_SUCCESS_STORY = (0, client_1.gql)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\tmutation deleteSuccessStory($id: ID!, $organization_id: ID!) {\n\t\tdeleteSuccessStory(id: $id, organization_id: $organization_id)\n\t}\n"], ["\n\tmutation deleteSuccessStory($id: ID!, $organization_id: ID!) {\n\t\tdeleteSuccessStory(id: $id, organization_id: $organization_id)\n\t}\n"])));
exports.DELETE_SUCCESS_STORY = DELETE_SUCCESS_STORY;
var UPSERT_SUCCESS_STORY = (0, client_1.gql)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\tmutation upsertSuccessStory(\n\t\t$id: ID\n\t\t$title: String!\n\t\t$description: String\n\t\t$sorting: Int\n\t\t$url: [VideoInput]\n\t\t$organization_id: ID!\n\t) {\n\t\tupsertSuccessStory(\n\t\t\tid: $id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tsorting: $sorting\n\t\t\turl: $url\n\t\t\torganization_id: $organization_id\n\t\t)\n\t}\n"], ["\n\tmutation upsertSuccessStory(\n\t\t$id: ID\n\t\t$title: String!\n\t\t$description: String\n\t\t$sorting: Int\n\t\t$url: [VideoInput]\n\t\t$organization_id: ID!\n\t) {\n\t\tupsertSuccessStory(\n\t\t\tid: $id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tsorting: $sorting\n\t\t\turl: $url\n\t\t\torganization_id: $organization_id\n\t\t)\n\t}\n"])));
exports.UPSERT_SUCCESS_STORY = UPSERT_SUCCESS_STORY;
var UPSERT_FINANCIAL_STATUS_SETTING = (0, client_1.gql)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\tmutation upsertFinancialStatusSetting(\n\t\t$id: ID\n\t\t$financial_info_id: ID!\n\t\t$profit_formula: String!\n\t\t$profit_message: String!\n\t\t$loss_formula: String!\n\t\t$loss_message: String!\n\t\t$neutral_formula: String!\n\t\t$neutral_message: String!\n\t\t$return_on_investment: String!\n\t\t$payback_period: String!\n\t\t$organization_id: ID!\n\t) {\n\t\tupsertFinancialStatusSetting(\n\t\t\tid: $id\n\t\t\tfinancial_info_id: $financial_info_id\n\t\t\tprofit_formula: $profit_formula\n\t\t\tprofit_message: $profit_message\n\t\t\tloss_message: $loss_message\n\t\t\tloss_formula: $loss_formula\n\t\t\tneutral_message: $neutral_message\n\t\t\tneutral_formula: $neutral_formula\n\t\t\treturn_on_investment: $return_on_investment\n\t\t\tpayback_period: $payback_period\n\t\t\torganization_id: $organization_id\n\t\t)\n\t}\n"], ["\n\tmutation upsertFinancialStatusSetting(\n\t\t$id: ID\n\t\t$financial_info_id: ID!\n\t\t$profit_formula: String!\n\t\t$profit_message: String!\n\t\t$loss_formula: String!\n\t\t$loss_message: String!\n\t\t$neutral_formula: String!\n\t\t$neutral_message: String!\n\t\t$return_on_investment: String!\n\t\t$payback_period: String!\n\t\t$organization_id: ID!\n\t) {\n\t\tupsertFinancialStatusSetting(\n\t\t\tid: $id\n\t\t\tfinancial_info_id: $financial_info_id\n\t\t\tprofit_formula: $profit_formula\n\t\t\tprofit_message: $profit_message\n\t\t\tloss_message: $loss_message\n\t\t\tloss_formula: $loss_formula\n\t\t\tneutral_message: $neutral_message\n\t\t\tneutral_formula: $neutral_formula\n\t\t\treturn_on_investment: $return_on_investment\n\t\t\tpayback_period: $payback_period\n\t\t\torganization_id: $organization_id\n\t\t)\n\t}\n"])));
exports.UPSERT_FINANCIAL_STATUS_SETTING = UPSERT_FINANCIAL_STATUS_SETTING;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
