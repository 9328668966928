"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_GUIDE = exports.DELETE_GUIDE = exports.UPSERT_GUIDE_CATEGORY = exports.DELETE_GUIDE_CATEGORY = void 0;
var client_1 = require("@apollo/client");
var DELETE_GUIDE_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation deleteGuideCategory($id: ID!, $organization_id: ID!) {\n\t\tdeleteGuideCategory(id: $id, organization_id: $organization_id)\n\t}\n"], ["\n\tmutation deleteGuideCategory($id: ID!, $organization_id: ID!) {\n\t\tdeleteGuideCategory(id: $id, organization_id: $organization_id)\n\t}\n"])));
exports.DELETE_GUIDE_CATEGORY = DELETE_GUIDE_CATEGORY;
var UPSERT_GUIDE_CATEGORY = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tmutation upsertGuideCategory($input: [guidePayload!]!) {\n\t\tupsertGuideCategory(input: $input)\n\t}\n"], ["\n\tmutation upsertGuideCategory($input: [guidePayload!]!) {\n\t\tupsertGuideCategory(input: $input)\n\t}\n"])));
exports.UPSERT_GUIDE_CATEGORY = UPSERT_GUIDE_CATEGORY;
var DELETE_GUIDE = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tmutation deleteGuide(\n\t\t$organization_id: ID!\n\t\t$guide_category_id: ID\n\t\t$id: ID!\n\t) {\n\t\tdeleteGuide(\n\t\t\tid: $id\n\t\t\torganization_id: $organization_id\n\t\t\tguide_category_id: $guide_category_id\n\t\t)\n\t}\n"], ["\n\tmutation deleteGuide(\n\t\t$organization_id: ID!\n\t\t$guide_category_id: ID\n\t\t$id: ID!\n\t) {\n\t\tdeleteGuide(\n\t\t\tid: $id\n\t\t\torganization_id: $organization_id\n\t\t\tguide_category_id: $guide_category_id\n\t\t)\n\t}\n"])));
exports.DELETE_GUIDE = DELETE_GUIDE;
var UPSERT_GUIDE = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tmutation upsertGuide(\n\t\t$guide_id: ID\n\t\t$title: String!\n\t\t$description: String\n\t\t$sorting: Int\n\t\t$guide_category_id: ID!\n\t\t$guide_media: GuideMediaInput!\n\t\t$organization_id: ID!\n\t) {\n\t\tupsertGuide(\n\t\t\tguide_id: $guide_id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tsorting: $sorting\n\t\t\tguide_category_id: $guide_category_id\n\t\t\tguide_media: $guide_media\n\t\t\torganization_id: $organization_id\n\t\t)\n\t}\n"], ["\n\tmutation upsertGuide(\n\t\t$guide_id: ID\n\t\t$title: String!\n\t\t$description: String\n\t\t$sorting: Int\n\t\t$guide_category_id: ID!\n\t\t$guide_media: GuideMediaInput!\n\t\t$organization_id: ID!\n\t) {\n\t\tupsertGuide(\n\t\t\tguide_id: $guide_id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tsorting: $sorting\n\t\t\tguide_category_id: $guide_category_id\n\t\t\tguide_media: $guide_media\n\t\t\torganization_id: $organization_id\n\t\t)\n\t}\n"])));
exports.UPSERT_GUIDE = UPSERT_GUIDE;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
