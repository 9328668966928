"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOGIN = void 0;
var client_1 = require("@apollo/client");
exports.LOGIN = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation Login($username: String!, $password: String!, $role: Int) {\n\t\tlogin(input: { username: $username, password: $password, role: $role }) {\n\t\t\tuser {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\temail\n\t\t\t\tpassword\n\t\t\t\ttoken\n\t\t\t\troles {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\tdetail {\n\t\t\t\t\tuserid\n\t\t\t\t\tfirst_name\n\t\t\t\t\tlast_name\n\t\t\t\t\tgender\n\t\t\t\t\taddress_country\n\t\t\t\t\taddress_state\n\t\t\t\t\taddress_zone\n\t\t\t\t\taddress_zone1\n\t\t\t\t\taddress_city\n\t\t\t\t\taddress_locality\n\t\t\t\t\tuser_group\n\t\t\t\t\tumva_photo_jpg_pending\n\t\t\t\t}\n\t\t\t\taccounts {\n\t\t\t\t\taccount_id\n\t\t\t\t\tbank_account {\n\t\t\t\t\t\tbank_id\n\t\t\t\t\t\tbank {\n\t\t\t\t\t\t\tbank_name\n\t\t\t\t\t\t\tbank_code\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation Login($username: String!, $password: String!, $role: Int) {\n\t\tlogin(input: { username: $username, password: $password, role: $role }) {\n\t\t\tuser {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\temail\n\t\t\t\tpassword\n\t\t\t\ttoken\n\t\t\t\troles {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\tdetail {\n\t\t\t\t\tuserid\n\t\t\t\t\tfirst_name\n\t\t\t\t\tlast_name\n\t\t\t\t\tgender\n\t\t\t\t\taddress_country\n\t\t\t\t\taddress_state\n\t\t\t\t\taddress_zone\n\t\t\t\t\taddress_zone1\n\t\t\t\t\taddress_city\n\t\t\t\t\taddress_locality\n\t\t\t\t\tuser_group\n\t\t\t\t\tumva_photo_jpg_pending\n\t\t\t\t}\n\t\t\t\taccounts {\n\t\t\t\t\taccount_id\n\t\t\t\t\tbank_account {\n\t\t\t\t\t\tbank_id\n\t\t\t\t\t\tbank {\n\t\t\t\t\t\t\tbank_name\n\t\t\t\t\t\t\tbank_code\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"])));
var templateObject_1;
