"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_SERVICE_DETAIL = exports.DELETE_SERVICE_DETAIL = exports.DELETE_SERVICE_CATEGORY = exports.UPSERT_SERVICE_CATEGORY = void 0;
var client_1 = require("@apollo/client");
var UPSERT_SERVICE_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation bulkUpsertServiceCategory($input: [addServiceCategoryPayload]) {\n\t\tbulkUpsertServiceCategory(input: $input)\n\t}\n"], ["\n\tmutation bulkUpsertServiceCategory($input: [addServiceCategoryPayload]) {\n\t\tbulkUpsertServiceCategory(input: $input)\n\t}\n"])));
exports.UPSERT_SERVICE_CATEGORY = UPSERT_SERVICE_CATEGORY;
var DELETE_SERVICE_CATEGORY = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tmutation deleteServiceCategory($id: ID, $organization_id: ID) {\n\t\tdeleteServiceCategory(id: $id, organization_id: $organization_id)\n\t}\n"], ["\n\tmutation deleteServiceCategory($id: ID, $organization_id: ID) {\n\t\tdeleteServiceCategory(id: $id, organization_id: $organization_id)\n\t}\n"])));
exports.DELETE_SERVICE_CATEGORY = DELETE_SERVICE_CATEGORY;
var UPSERT_SERVICE_DETAIL = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tmutation upsertServiceDetail(\n\t\t$organization_id: ID!\n\t\t$service_detail_id: ID\n\t\t$service_category_id: ID!\n\t\t$name: String!\n\t\t$service_media: ServiceMediaInput!\n\t\t$sorting: Int\n\t\t$description: String\n\t\t$phone: String\n\t\t$website: String\n\t\t$email: String\n\t\t$address: String\n\t) {\n\t\tupsertServiceDetail(\n\t\t\torganization_id: $organization_id\n\t\t\tservice_detail_id: $service_detail_id\n\t\t\tservice_category_id: $service_category_id\n\t\t\tname: $name\n\t\t\tservice_media: $service_media\n\t\t\tsorting: $sorting\n\t\t\tdescription: $description\n\t\t\tphone: $phone\n\t\t\twebsite: $website\n\t\t\temail: $email\n\t\t\taddress: $address\n\t\t)\n\t}\n"], ["\n\tmutation upsertServiceDetail(\n\t\t$organization_id: ID!\n\t\t$service_detail_id: ID\n\t\t$service_category_id: ID!\n\t\t$name: String!\n\t\t$service_media: ServiceMediaInput!\n\t\t$sorting: Int\n\t\t$description: String\n\t\t$phone: String\n\t\t$website: String\n\t\t$email: String\n\t\t$address: String\n\t) {\n\t\tupsertServiceDetail(\n\t\t\torganization_id: $organization_id\n\t\t\tservice_detail_id: $service_detail_id\n\t\t\tservice_category_id: $service_category_id\n\t\t\tname: $name\n\t\t\tservice_media: $service_media\n\t\t\tsorting: $sorting\n\t\t\tdescription: $description\n\t\t\tphone: $phone\n\t\t\twebsite: $website\n\t\t\temail: $email\n\t\t\taddress: $address\n\t\t)\n\t}\n"])));
exports.UPSERT_SERVICE_DETAIL = UPSERT_SERVICE_DETAIL;
var DELETE_SERVICE_DETAIL = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tmutation deleteServiceDetail(\n\t\t$id: ID!\n\t\t$organization_id: ID!\n\t\t$service_category_id: ID!\n\t) {\n\t\tdeleteServiceDetail(\n\t\t\tid: $id\n\t\t\torganization_id: $organization_id\n\t\t\tservice_category_id: $service_category_id\n\t\t)\n\t}\n"], ["\n\tmutation deleteServiceDetail(\n\t\t$id: ID!\n\t\t$organization_id: ID!\n\t\t$service_category_id: ID!\n\t) {\n\t\tdeleteServiceDetail(\n\t\t\tid: $id\n\t\t\torganization_id: $organization_id\n\t\t\tservice_category_id: $service_category_id\n\t\t)\n\t}\n"])));
exports.DELETE_SERVICE_DETAIL = DELETE_SERVICE_DETAIL;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
