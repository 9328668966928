"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_GUIDE_CATEGORY = exports.GET_GUIDE = void 0;
var client_1 = require("@apollo/client");
var GET_GUIDE_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tquery getGuideCategoryByOrganization($organization_id: ID!) {\n\t\tgetGuideCategoryByOrganization(organization_id: $organization_id) {\n\t\t\tid\n\t\t\tname\n\t\t\ticon\n\t\t\ticon_web\n\t\t\tsorting\n\t\t\torganization_id\n\t\t\tguide {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tdescription\n\t\t\t\tsorting\n\t\t\t\tguide_category_id\n\t\t\t\tguide_media {\n\t\t\t\t\tid\n\t\t\t\t\tthumbnail_link\n\t\t\t\t\tvideo_link\n\t\t\t\t\tweb_thumbnail_link\n\t\t\t\t\tweb_video_link\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery getGuideCategoryByOrganization($organization_id: ID!) {\n\t\tgetGuideCategoryByOrganization(organization_id: $organization_id) {\n\t\t\tid\n\t\t\tname\n\t\t\ticon\n\t\t\ticon_web\n\t\t\tsorting\n\t\t\torganization_id\n\t\t\tguide {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tdescription\n\t\t\t\tsorting\n\t\t\t\tguide_category_id\n\t\t\t\tguide_media {\n\t\t\t\t\tid\n\t\t\t\t\tthumbnail_link\n\t\t\t\t\tvideo_link\n\t\t\t\t\tweb_thumbnail_link\n\t\t\t\t\tweb_video_link\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"])));
exports.GET_GUIDE_CATEGORY = GET_GUIDE_CATEGORY;
var GET_GUIDE = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tquery getGuideByOrganization($guide_category_id: ID!, $organization_id: ID!) {\n\t\tgetGuideByOrganization(\n\t\t\tguide_category_id: $guide_category_id\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\torganization_id\n\t\t\tid\n\t\t\tsorting\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tguide_media {\n\t\t\t\tthumbnail_link\n\t\t\t\tvideo_link\n\t\t\t}\n\t\t\tguide_category_id\n\t\t}\n\t}\n"], ["\n\tquery getGuideByOrganization($guide_category_id: ID!, $organization_id: ID!) {\n\t\tgetGuideByOrganization(\n\t\t\tguide_category_id: $guide_category_id\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\torganization_id\n\t\t\tid\n\t\t\tsorting\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tguide_media {\n\t\t\t\tthumbnail_link\n\t\t\t\tvideo_link\n\t\t\t}\n\t\t\tguide_category_id\n\t\t}\n\t}\n"])));
exports.GET_GUIDE = GET_GUIDE;
var templateObject_1, templateObject_2;
