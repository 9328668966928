"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_USER_TYPE = exports.GET_TABULAR_SORTING_ORDER = exports.GET_SUCCESS_STORY = exports.GET_FINANCIAL_TABULAR_OPTIONS = exports.GET_FINANCIAL_INFO_BY_USER_TYPE = exports.GET_FINANCIAL_INFO_BY_CATEGORY = exports.GET_FINANCIAL_INFO = exports.GET_FINANCIAL_CATEGORY_ORDER = exports.GET_FINANCIAL_CATEGORY = exports.GET_DEPENDENCY_FINANCIAL = exports.GET_DATA_TYPE = exports.GET_ACCOUNT_TYPE = exports.FRAGMENT_INFO = exports.FRAGMENT_CATEGORY = void 0;
var client_1 = require("@apollo/client");
exports.FRAGMENT_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfragment ChildFields on FinancialCategory {\n\t\tid\n\t\tname\n\t\tchild_count\n\n\t\tparent {\n\t\t\tid\n\t\t}\n\t}\n\n\tfragment ChildRecursive on FinancialCategory {\n\t\tchild {\n\t\t\t...ChildFields\n\t\t\tchild {\n\t\t\t\t...ChildFields\n\t\t\t\tchild {\n\t\t\t\t\t...ChildFields\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tfragment ChildFields on FinancialCategory {\n\t\tid\n\t\tname\n\t\tchild_count\n\n\t\tparent {\n\t\t\tid\n\t\t}\n\t}\n\n\tfragment ChildRecursive on FinancialCategory {\n\t\tchild {\n\t\t\t...ChildFields\n\t\t\tchild {\n\t\t\t\t...ChildFields\n\t\t\t\tchild {\n\t\t\t\t\t...ChildFields\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"])));
var GET_FINANCIAL_CATEGORY = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tquery getFinancialCategoryByPublished($organization_id: ID!) {\n\t\tgetFinancialCategoryByPublished(\n\t\t\tpublished: null\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\t...ChildFields\n\t\t\t...ChildRecursive\n\t\t}\n\t}\n\t", "\n"], ["\n\tquery getFinancialCategoryByPublished($organization_id: ID!) {\n\t\tgetFinancialCategoryByPublished(\n\t\t\tpublished: null\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\t...ChildFields\n\t\t\t...ChildRecursive\n\t\t}\n\t}\n\t", "\n"])), exports.FRAGMENT_CATEGORY);
exports.GET_FINANCIAL_CATEGORY = GET_FINANCIAL_CATEGORY;
exports.FRAGMENT_INFO = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tfragment InfoFields on FinancialInfo {\n\t\tid\n\t\tname\n\t\tchild_count\n\t\tfinancial_category_id\n\t\tdata_type_id\n\t\torder_by\n\t\tdependent_id\n\t\tcode\n\t\teditable\n\t\tcalculation\n\t\tparent {\n\t\t\tid\n\t\t}\n\t\tuser_types {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\tfinancial_category {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\tdata_type {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\toptions {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\tparent {\n\t\t\tid\n\t\t}\n\t\tcolor_code\n\t\tkey\n\t\taccount_type_id\n\t\ticon\n\t\ticon_web\n\t\tis_pie_chart\n\t\torganization_id\n\t}\n\n\tfragment InfoRecursive on FinancialInfo {\n\t\tchild {\n\t\t\t...InfoFields\n\t\t}\n\t}\n"], ["\n\tfragment InfoFields on FinancialInfo {\n\t\tid\n\t\tname\n\t\tchild_count\n\t\tfinancial_category_id\n\t\tdata_type_id\n\t\torder_by\n\t\tdependent_id\n\t\tcode\n\t\teditable\n\t\tcalculation\n\t\tparent {\n\t\t\tid\n\t\t}\n\t\tuser_types {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\tfinancial_category {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\tdata_type {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\toptions {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\tparent {\n\t\t\tid\n\t\t}\n\t\tcolor_code\n\t\tkey\n\t\taccount_type_id\n\t\ticon\n\t\ticon_web\n\t\tis_pie_chart\n\t\torganization_id\n\t}\n\n\tfragment InfoRecursive on FinancialInfo {\n\t\tchild {\n\t\t\t...InfoFields\n\t\t}\n\t}\n"])));
var GET_FINANCIAL_INFO = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tquery getFinancialInfo($organization_id: ID!) {\n\t\tgetFinancialInfo(organization_id: $organization_id) {\n\t\t\t...InfoFields\n\t\t\t...InfoRecursive\n\t\t}\n\t}\n\t", "\n"], ["\n\tquery getFinancialInfo($organization_id: ID!) {\n\t\tgetFinancialInfo(organization_id: $organization_id) {\n\t\t\t...InfoFields\n\t\t\t...InfoRecursive\n\t\t}\n\t}\n\t", "\n"])), exports.FRAGMENT_INFO);
exports.GET_FINANCIAL_INFO = GET_FINANCIAL_INFO;
var GET_FINANCIAL_INFO_BY_USER_TYPE = (0, client_1.gql)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tquery getFinancialInfoByUserType($organization_id: ID!, $user_type_id: ID) {\n\t\tgetFinancialInfoByUserType(\n\t\t\torganization_id: $organization_id\n\t\t\tuser_type_id: $user_type_id\n\t\t) {\n\t\t\t...InfoFields\n\t\t\t...InfoRecursive\n\t\t}\n\t}\n\t", "\n"], ["\n\tquery getFinancialInfoByUserType($organization_id: ID!, $user_type_id: ID) {\n\t\tgetFinancialInfoByUserType(\n\t\t\torganization_id: $organization_id\n\t\t\tuser_type_id: $user_type_id\n\t\t) {\n\t\t\t...InfoFields\n\t\t\t...InfoRecursive\n\t\t}\n\t}\n\t", "\n"])), exports.FRAGMENT_INFO);
exports.GET_FINANCIAL_INFO_BY_USER_TYPE = GET_FINANCIAL_INFO_BY_USER_TYPE;
var GET_FINANCIAL_INFO_BY_CATEGORY = (0, client_1.gql)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\tquery getFinancialInfoByCategory(\n\t\t$financial_category_id: ID!\n\t\t$organization_id: ID!\n\t) {\n\t\tgetFinancialInfoByCategory(\n\t\t\tfinancial_category_id: $financial_category_id\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\t...InfoFields\n\t\t\t...InfoRecursive\n\t\t}\n\t}\n\t", "\n"], ["\n\tquery getFinancialInfoByCategory(\n\t\t$financial_category_id: ID!\n\t\t$organization_id: ID!\n\t) {\n\t\tgetFinancialInfoByCategory(\n\t\t\tfinancial_category_id: $financial_category_id\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\t...InfoFields\n\t\t\t...InfoRecursive\n\t\t}\n\t}\n\t", "\n"])), exports.FRAGMENT_INFO);
exports.GET_FINANCIAL_INFO_BY_CATEGORY = GET_FINANCIAL_INFO_BY_CATEGORY;
var GET_DATA_TYPE = (0, client_1.gql)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tquery getDataType {\n\t\tgetDataType {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"], ["\n\tquery getDataType {\n\t\tgetDataType {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"])));
exports.GET_DATA_TYPE = GET_DATA_TYPE;
var GET_USER_TYPE = (0, client_1.gql)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\tquery getUserType {\n\t\tgetUserType {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"], ["\n\tquery getUserType {\n\t\tgetUserType {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"])));
exports.GET_USER_TYPE = GET_USER_TYPE;
var GET_FINANCIAL_TABULAR_OPTIONS = (0, client_1.gql)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\tquery getFinancialTabularById($id: ID!) {\n\t\tgetFinancialTabularById(id: $id) {\n\t\t\t...InfoFields\n\t\t\t...InfoRecursive\n\t\t}\n\t}\n\t", "\n"], ["\n\tquery getFinancialTabularById($id: ID!) {\n\t\tgetFinancialTabularById(id: $id) {\n\t\t\t...InfoFields\n\t\t\t...InfoRecursive\n\t\t}\n\t}\n\t", "\n"])), exports.FRAGMENT_INFO);
exports.GET_FINANCIAL_TABULAR_OPTIONS = GET_FINANCIAL_TABULAR_OPTIONS;
var GET_TABULAR_SORTING_ORDER = (0, client_1.gql)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\tquery getTabularSortingOrder($parent_id: ID!, $organization_id: ID!) {\n\t\tgetTabularSortingOrder(\n\t\t\tparent_id: $parent_id\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\tid\n\t\t\tname\n\t\t\torder_by\n\t\t\tkey\n\t\t\tcolor_code\n\t\t\tcode\n\t\t\teditable\n\t\t\tcalculation\n\t\t\tdependent_id\n\t\t\torganization_id\n\t\t}\n\t}\n"], ["\n\tquery getTabularSortingOrder($parent_id: ID!, $organization_id: ID!) {\n\t\tgetTabularSortingOrder(\n\t\t\tparent_id: $parent_id\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\tid\n\t\t\tname\n\t\t\torder_by\n\t\t\tkey\n\t\t\tcolor_code\n\t\t\tcode\n\t\t\teditable\n\t\t\tcalculation\n\t\t\tdependent_id\n\t\t\torganization_id\n\t\t}\n\t}\n"])));
exports.GET_TABULAR_SORTING_ORDER = GET_TABULAR_SORTING_ORDER;
var GET_DEPENDENCY_FINANCIAL = (0, client_1.gql)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n\tquery getDependencyFinancialInfo(\n\t\t$financial_category_id: ID!\n\t\t$organization_id: ID!\n\t) {\n\t\tgetDependencyFinancialInfo(\n\t\t\tfinancial_category_id: $financial_category_id\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\tid\n\t\t\tname\n\t\t\torder_by\n\t\t\torganization_id\n\t\t}\n\t}\n"], ["\n\tquery getDependencyFinancialInfo(\n\t\t$financial_category_id: ID!\n\t\t$organization_id: ID!\n\t) {\n\t\tgetDependencyFinancialInfo(\n\t\t\tfinancial_category_id: $financial_category_id\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\tid\n\t\t\tname\n\t\t\torder_by\n\t\t\torganization_id\n\t\t}\n\t}\n"])));
exports.GET_DEPENDENCY_FINANCIAL = GET_DEPENDENCY_FINANCIAL;
var GET_SUCCESS_STORY = (0, client_1.gql)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n\tquery getSuccessStory($organization_id: ID!) {\n\t\tgetSuccessStory(organization_id: $organization_id) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tsorting\n\t\t\turl\n\t\t\tweb_url\n\t\t\torganization_id\n\t\t}\n\t}\n"], ["\n\tquery getSuccessStory($organization_id: ID!) {\n\t\tgetSuccessStory(organization_id: $organization_id) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tsorting\n\t\t\turl\n\t\t\tweb_url\n\t\t\torganization_id\n\t\t}\n\t}\n"])));
exports.GET_SUCCESS_STORY = GET_SUCCESS_STORY;
var GET_FINANCIAL_CATEGORY_ORDER = (0, client_1.gql)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n\tquery getFinancialCategoryOrder($parent_id: ID, $organization_id: ID) {\n\t\tgetFinancialCategoryOrder(\n\t\t\tparent_id: $parent_id\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\tid\n\t\t\tname\n\t\t\torganization_id\n\t\t\torder_by\n\t\t}\n\t}\n"], ["\n\tquery getFinancialCategoryOrder($parent_id: ID, $organization_id: ID) {\n\t\tgetFinancialCategoryOrder(\n\t\t\tparent_id: $parent_id\n\t\t\torganization_id: $organization_id\n\t\t) {\n\t\t\tid\n\t\t\tname\n\t\t\torganization_id\n\t\t\torder_by\n\t\t}\n\t}\n"])));
exports.GET_FINANCIAL_CATEGORY_ORDER = GET_FINANCIAL_CATEGORY_ORDER;
var GET_ACCOUNT_TYPE = (0, client_1.gql)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n\tquery {\n\t\tgetAccountType(published: true) {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"], ["\n\tquery {\n\t\tgetAccountType(published: true) {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"])));
exports.GET_ACCOUNT_TYPE = GET_ACCOUNT_TYPE;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
