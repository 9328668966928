"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_LANGUAGE_WITH_TRANSLATION = exports.GET_LANGUAGES = void 0;
var client_1 = require("@apollo/client");
var GET_LANGUAGE_WITH_TRANSLATION = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tquery getLanguageWithTranslation($country_code: String!) {\n\t\tgetLanguageWithTranslation(country_code: $country_code) {\n\t\t\tlabel\n\t\t\tcode\n\t\t\ttranslation\n\t\t\tis_default\n\t\t}\n\t}\n"], ["\n\tquery getLanguageWithTranslation($country_code: String!) {\n\t\tgetLanguageWithTranslation(country_code: $country_code) {\n\t\t\tlabel\n\t\t\tcode\n\t\t\ttranslation\n\t\t\tis_default\n\t\t}\n\t}\n"])));
exports.GET_LANGUAGE_WITH_TRANSLATION = GET_LANGUAGE_WITH_TRANSLATION;
var GET_LANGUAGES = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tquery getLanguage($country_code: String!) {\n\t\tgetLanguage(country_code: $country_code) {\n\t\t\tid\n\t\t\tcode\n\t\t\tname\n\t\t\tis_default\n\t\t}\n\t}\n"], ["\n\tquery getLanguage($country_code: String!) {\n\t\tgetLanguage(country_code: $country_code) {\n\t\t\tid\n\t\t\tcode\n\t\t\tname\n\t\t\tis_default\n\t\t}\n\t}\n"])));
exports.GET_LANGUAGES = GET_LANGUAGES;
var templateObject_1, templateObject_2;
