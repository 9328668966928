"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SERVICE_CATEGORY_BY_ORGANIZATION = void 0;
var client_1 = require("@apollo/client");
var GET_SERVICE_CATEGORY_BY_ORGANIZATION = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tquery getAllServiceCategoryByOrganization($organization_id: ID!) {\n\t\tgetAllServiceCategoryByOrganization(organization_id: $organization_id) {\n\t\t\tid\n\t\t\torganization_id\n\t\t\tname\n\t\t\ticon\n\t\t\ticon_web\n\t\t\tsorting\n\t\t\tservice_details {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\ticon\n\t\t\t\tthumbnail\n\t\t\t\tvideo\n\t\t\t\ticon_web\n\t\t\t\tvideo_web\n\t\t\t\tthumbnail_web\n\t\t\t\tdescription\n\t\t\t\tphone\n\t\t\t\temail\n\t\t\t\twebsite\n\t\t\t\tsorting\n\t\t\t\tservice_category_id\n\t\t\t\taddress\n\t\t\t\torganization_id\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery getAllServiceCategoryByOrganization($organization_id: ID!) {\n\t\tgetAllServiceCategoryByOrganization(organization_id: $organization_id) {\n\t\t\tid\n\t\t\torganization_id\n\t\t\tname\n\t\t\ticon\n\t\t\ticon_web\n\t\t\tsorting\n\t\t\tservice_details {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\ticon\n\t\t\t\tthumbnail\n\t\t\t\tvideo\n\t\t\t\ticon_web\n\t\t\t\tvideo_web\n\t\t\t\tthumbnail_web\n\t\t\t\tdescription\n\t\t\t\tphone\n\t\t\t\temail\n\t\t\t\twebsite\n\t\t\t\tsorting\n\t\t\t\tservice_category_id\n\t\t\t\taddress\n\t\t\t\torganization_id\n\t\t\t}\n\t\t}\n\t}\n"])));
exports.GET_SERVICE_CATEGORY_BY_ORGANIZATION = GET_SERVICE_CATEGORY_BY_ORGANIZATION;
var templateObject_1;
